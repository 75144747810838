<template>
  <div>
    <div class="sctp-bg-white">
      <div style="width: 400px" class="sctp-pad-lr15 sctp-pad-tb10">
        <el-form :rules="formRules" size="small" ref="form" :model="form" label-width="120px">
          <el-form-item label="新支付密码" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="确认支付密码" prop="rePassword">
            <el-input type="password" v-model="form.rePassword"></el-input>
          </el-form-item>
          <el-form-item label="邮箱验证码" prop="code">
            <el-input v-model="form.code">
              <div slot="append">
                <template v-if="!sendCodeConfig.send">
                  <el-button
                    @click="sendCodeConfig.sendCode"
                    :loading="!sendCodeConfig.canClick"
                  >
                    发送验证码
                  </el-button>
                </template>
                <template v-else>
                  <count-down @finish="sendCodeConfig.finishCountDown" format="ss秒后重试" :time="60 * 1000"></count-down>
                </template>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitClick">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pay_password_setting',
  components: {
    CountDown: () => import('@CMP/common/CountDown')
  },
  data() {
    return {
      form: {
        password: null,
        rePassword: null,
        code: null
      },
      formRules: {
        password: [
          {required: true, message: '请输入密码', trigger: ['blur', 'change']},
          // {min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change']}
          {
            validator: (rule, value, callback) => {
              if (/^[0-9]{6}$/.test(value)) {
                callback()
              } else {
                callback(new Error('密码限制六位数字'))
              }
            }, trigger: ['blur', 'change']
          },
        ],
        rePassword: [
          {required: true, message: '请确认密码', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              const {password} = this.form
              if (password === value) {
                callback()
              } else {
                callback(new Error('两次密码不一致'))
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: ['blur', 'change']}
        ]
      },
      sendCodeConfig: {
        canClick: true,
        send: false,
        finishCountDown: () => {
          this.sendCodeConfig.send = false
        },
        sendCode: () => {
          // this.$refs['form'].validateField('', )
          this.sendCodeConfig.canClick = false
          this.sendCodeConfig.doSendCode()
            .then(res => {
              console.log(1111)
              this.$message.success('发送成功，请注意查收!')
              this.sendCodeConfig.send = true
            })
            .catch(res => {
              this.sendCodeConfig.send = true
            })
            .finally(() => {
              this.sendCodeConfig.canClick = true
            })
        },
        doSendCode: () => {
          return this.$request.post({
            reqcode: '1114',
            reqdesc: '发送验证码',
            userId: this.user.userId
          })
        }
      }
    }
  },
  methods: {
    onSubmitClick() {
      this.$refs.form.validate(res => {
        if (res) {
          this.doSubmit()
        }
      })
    },
    doSubmit() {
      const {form: {password, code}} = this
      this.$request.post({
        reqcode: '1113',
        reqdesc: '修改支付密码',
        password: password,
        code: code,
        userId: this.user.userId
      }).then(res => {
        this.$message.success('修改成功')
        this.$refs.form.resetFields()
      })
    }
  },
  beforeMount() {
  }

}
</script>

<style scoped lang="scss">
</style>
